import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useEffect } from "react";
import { AIRLINE, CLIENT_CODE, STORAGE_KEY__AIRLINE, STORAGE_KEY__CLIENT_CODE } from "../constants";
import { useLocalStorage } from "usehooks-ts";

type Props = {
    children?: React.ReactNode;
};

const AppParamProvider: React.FC<Props> = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const { clientCode, airline } = useParams();
    const [selectedClientCode, setSelectedClientCode] = useLocalStorage(STORAGE_KEY__CLIENT_CODE, clientCode || CLIENT_CODE);
    const [selectedAirline, setSelectedAirline] = useLocalStorage(STORAGE_KEY__AIRLINE, airline || AIRLINE);
  
    useEffect(() => {
        const defaultPath = `/${selectedClientCode}/${selectedAirline}`;
        if (isAuthenticated && (!clientCode || !airline)) {
            setSelectedClientCode(selectedClientCode);
            setSelectedAirline(selectedAirline);
            console.info('no client code or airline selected... navigating to default route:', defaultPath)
            navigate(defaultPath, { state: location, replace: true });
        }
    }, [location, navigate, isAuthenticated, selectedClientCode, selectedAirline, setSelectedAirline, setSelectedClientCode, clientCode, airline])

    return <>{children}</>;
};

export default AppParamProvider;