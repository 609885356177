import Grid from '@mui/material/Grid';
import { API } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import PageHeader from '../../common/Components/PageHeader';
import { Paper, Stack } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import CateringRouteGrid from './CateringRouteGrid';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import 'dayjs/locale/de';
import { toast } from 'react-toastify'; 

function CateringRoutes() {
    const { clientCode } = useParams();
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([dayjs(), dayjs().add(7, "days")]);
    const [loading, setLoading] = useState(false);

    const downloadMealOrderFile = async (type: "csv" | "pdf") => {
        setLoading(true);

        try {
            const response = await API.get('api', `/api/mealorder/${clientCode}/${type}/${dateRange[0]?.format("YYYY-MM-DD")}/${dateRange[1]?.format("YYYY-MM-DD")}`, {});
            window.location = response.url;
        } catch (error: any) {
            console.log(error.message, error)
            toast.error('An error occurred while generating meal order file.')
        } finally {
            setLoading(false);
        }
    }

    const downloadScheduleFile = async () => {
        setLoading(true);

        try {
            const response = await API.get('api', `/api/cateringroutes/query/${clientCode}/from/${dateRange[0]?.format("YYYY-MM-DD")}/to/${dateRange[1]?.format("YYYY-MM-DD")}/export`, {});
            window.location = response;
        } catch (error: any) {
            console.log(error.message, error)
            toast.error('An error occurred while generating meal order file.')
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <PageHeader title={t('Catering Routes')} >
                    <Stack direction='row' spacing={2}>
                        <LoadingButton loading={loading} variant="contained" onClick={() => downloadScheduleFile()}>Download Schedule</LoadingButton>
                        <LoadingButton loading={loading} variant="contained" onClick={() => downloadMealOrderFile("csv")}>Download CSV</LoadingButton>
                        <LoadingButton loading={loading} variant="contained" onClick={() => downloadMealOrderFile("pdf")}>Download PDF</LoadingButton>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en'>
                            <DateRangePicker value={dateRange} format='DD.MM.YYYY' onChange={setDateRange} slotProps={{ textField: { size: 'small' } }} />
                        </LocalizationProvider>
                    </Stack>
                </PageHeader>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 0, display: 'flex', flexDirection: 'column', minHeight: '100px' }}>
                    <CateringRouteGrid dateFrom={dateRange[0]?.format("YYYY-MM-DD")} dateTo={dateRange[1]?.format("YYYY-MM-DD")} />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default CateringRoutes;