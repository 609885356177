import App from '../App';
import ErrorPage from '../ErrorPage';
import CateringRoutes from './CateringRoutes';
import SignIn from './SignIn';
import { CATERING_ROUTES, HOME, SIGN_IN } from '../common/paths';
const routes = [
  {
    path: '',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: HOME,
        children: [
          {
            path: CATERING_ROUTES,
            children: [
              {
                path: '',             
                element: <CateringRoutes />,
              },              
            ]
          },
        ],
      },      
      {
        path: SIGN_IN,
        element: <SignIn />
      },
    ],
  },
];

export default routes;