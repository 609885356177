
import { TFunction } from 'i18next';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { CATERING_ROUTES } from '../common/paths';

export interface INavigationItem {
    id: string,
    icon?: React.ElementType,
    label: string,
    children?: INavigationItem[],
    path: string,
    rootPath: string,
}

export interface INavigationGroup {
    id: string,
    label: string,
    children: INavigationItem[],
}

export function getNavigationItemGroups(t: TFunction, rootPath: string): INavigationGroup[] {
    return [
        {
            id: 'general',
            label: t('General'),
            children: [
                {
                    id: 'catering-routes',
                    label: t('Catering Routes'),
                    icon: ShareIcon,
                    path: CATERING_ROUTES,
                    rootPath,
                },
            ],
        },
    ]
}