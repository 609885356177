import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser, CognitoAccessToken } from 'amazon-cognito-identity-js';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIGN_IN } from '../common/paths';
import { AmplifyConfigAuth } from '../config/auth';
import { AmplifyConfigAPI } from '../config/api';

Amplify.configure({
    Auth: AmplifyConfigAuth,
    API: AmplifyConfigAPI,
});

interface UseAuth {
    isLoading: boolean;
    isAuthenticated: boolean;
    user: CognitoUser;
    signIn: (username: string, password: string) => Promise<Result>;
    signOut: () => void;
    setNewPassword: (session: any, password: string, requiredAttributes: any) => Promise<Result>;
    getUser: () => Promise<any>;
    getAccessToken: () => Promise<CognitoAccessToken>;
}

interface Result {
    success: boolean;
    message: string;
    passwordChangeRequired?: boolean;
    session?: any;
}

type Props = {
    children?: React.ReactNode;
};

const authContext = createContext({} as UseAuth);

export const AuthProvider: React.FC<Props> = ({ children }) => {
    const auth = useProvideAuth();
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const signInPath = `/${SIGN_IN}`;
        if (!auth.isLoading && !auth.isAuthenticated && location.pathname !== signInPath) {
            console.info('user not logged in... navigating to:', signInPath)
            navigate(signInPath, { state: location, replace: true });
        }
    }, [location, auth.isAuthenticated, auth.isLoading, navigate])
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
    return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState<any>();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((result) => {
                setUser(result);
                setIsAuthenticated(true);
                setIsLoading(false);
            })
            .catch(() => {
                setUser(undefined);
                setIsAuthenticated(false);
                setIsLoading(false);
            });
    }, []);

    const signIn = async (username: string, password: string) => {
        try {
            setIsLoading(true);
            const result = await Auth.signIn(username, password);
            setUser(result);
            const challenge = result.challengeName;
            if (challenge) {
                switch (challenge) {
                    case 'NEW_PASSWORD_REQUIRED': {
                        setIsLoading(false);
                        return { success: false, passwordChangeRequired: true, session: result, message: '' };
                    }
                    default: {
                        console.error(`Challenge ${challenge} not implemented.`);
                        return {
                            success: false,
                            message: `Sing in failed. Challenge ${challenge} not implemented.`,
                        };
                    }
                }
            }
            setIsAuthenticated(true);
            setIsLoading(false);
            return { success: true, message: '' };
        } catch (error: any) {
            setIsLoading(false);
            console.error(error);
            return {
                success: false,
                message: error.message || 'Sing in failed',
            };
        }
    };

    const setNewPassword = async (session: any, password: string, requiredAttributes: any) => {
        try {
            setIsLoading(true);
            const result = await Auth.completeNewPassword(session, password, requiredAttributes);
            setUser(result);
            setIsAuthenticated(true);
            setIsLoading(false);
            return { success: true, message: '' };
        } catch (error: any) {
            setIsLoading(false);
            console.error(error);
            return {
                success: false,
                message: error.message || 'Failed to change password',
            };
        }
    }

    const signOut = async () => {
        try {
            setIsLoading(true);
            await Auth.signOut();
            setUser(undefined);
            setIsAuthenticated(false);
            setIsLoading(false);
            return { success: true, message: '' };
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            return {
                success: false,
                message: 'Sing out failed',
            };
        }
    };

    const getUser = async () => {
        return await Auth.currentUserInfo();
    };;

    const getAccessToken = async (): Promise<CognitoAccessToken> => {
        const session = await Auth.currentSession();
        return await session.getAccessToken();
    };

    return {
        isLoading,
        isAuthenticated,
        user,
        signIn,
        signOut,
        setNewPassword,
        getUser,
        getAccessToken,
    };
};
