import Box from '@mui/material/Box';
import Header from './Header/Header';
import React from 'react';
import Navigation from './Navigation/Navigation';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { Container, useTheme } from '@mui/material';
import { CustomTheme } from './theme/CustomTheme';
import { AuthProvider } from './hooks/useAuth';
import './App.scss'
import { DRAWER_WIDTH } from './common/constants'
import AppParamProvider from './common/Components/AppParamProvider';

function App() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme<CustomTheme>();

  const handleDrawerToggle = () => {
    setDrawerOpen((value) => !value);
  };

  return (
    <AuthProvider>
      <AppParamProvider>
        <Box sx={{ display: 'flex' }}>
          <Header
            title={t('Meal Catering')}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Navigation
            drawerWidth={DRAWER_WIDTH}
            drawerOpen={drawerOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: {
                sm: 'calc(100vh - 64px)',
                xs: 'calc(100vh - 56px)',
              },
              overflow: 'auto',
              mt: {
                sm: '64px',
                xs: '56px',
              },
              backgroundColor: theme.palette.secondary.lighter,
              borderTopLeftRadius: { xs: 0, md: 10 },
              boxShadow: theme.customShadows.inset
            }}
          >
            <Container maxWidth="xl" sx={{ my: { xs: 2, sm: 4 } }}>
              <Outlet />
            </Container>
          </Box>
        </Box>
      </AppParamProvider>
    </AuthProvider>
  );
}

export default App;
