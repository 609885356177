import { Auth } from "aws-amplify";

export const AmplifyConfigAPI = {
    endpoints: [
        {
            name: 'api',
            endpoint: process.env.REACT_APP_API_ENDPOINT_EXTERN, //process.env.REACT_APP_API_ENDPOINT,
            custom_header: async () => {
                return {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'Content-Type': 'application/json',
                };
            }
        },
        {
            name: 'extapi',
            endpoint: process.env.REACT_APP_API_ENDPOINT_EXTERN,
            custom_header: async () => {
                return {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                    'Content-Type': 'application/json',
                };
            }
        },
    ],
}